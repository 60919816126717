import React from 'react';
import { useSelector } from 'react-redux';

import sessionSelectors from 'features/shared/services/session/selectors.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../../../shared/components/table';
import { i18n } from 'i18n/index.js';
import Heading2 from 'features/report/shared/components/heading2';
import { formatNumber } from 'features/shared/utils/number';
import GoalTableIcon from 'features/report/shared/components/goalTableIcon';

const useStyles = createUseStyles(theme => ({
  container: {
    breakInside: 'avoid'
  },
  header: {
    marginTop: '50px'
  },
  goalIcon: {
    fill: theme.primaryColor,
    width: 30
  }
}));

const HistoricalReturnTable = ({ goals, keyStatistics, showGoalIcons }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const timeRanges = [
    {
      key: '3M',
      value: i18n('report.historicalReturnTable.3M', translationsConfig)
    },
    {
      key: 'YTD',
      value: i18n('report.historicalReturnTable.YTD', translationsConfig)
    },
    {
      key: '1Y',
      value: i18n('report.historicalReturnTable.1Y', translationsConfig)
    },
    {
      key: '3Y',
      value: i18n('report.historicalReturnTable.3Y', translationsConfig)
    },
    {
      key: '5Y',
      value: i18n('report.historicalReturnTable.5Y', translationsConfig)
    },
    {
      key: '10Y',
      value: i18n('report.historicalReturnTable.10Y', translationsConfig)
    }
  ];

  const getGrowth = React.useCallback(
    ({
      goalId,
      timeRange
    }: {
      goalId: string;
      timeRange: { key: string; value: string };
    }) => {
      const stats = keyStatistics[timeRange.key].find(k => k.goalId === goalId);

      if (!stats) return null;

      return formatNumber(cultureCode, stats.growth * 100, 0, 1);
    },
    [keyStatistics, cultureCode]
  );

  return (
    <div className={classes.container}>
      <Heading2 className={classes.header}>
        {i18n(
          'report.historicalReturn.historicalDevelopmentInPercent',
          translationsConfig
        )}
      </Heading2>

      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showGoalIcons && <TableHeaderCell />}
            <TableHeaderCell>
              {i18n('report.purpose.goal', translationsConfig)}
            </TableHeaderCell>
            {timeRanges.map(t => (
              <TableHeaderCell key={t.key}>{t.value}</TableHeaderCell>
            ))}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {goals.map(goal => (
            <TableBodyRow key={goal.goalId} className={classes.row}>
              {showGoalIcons && <TableBodyCell className={classes.goalIcon}>
                <GoalTableIcon name={goal.name} icon={goal.icon} />
              </TableBodyCell>}
              <TableBodyCell>{goal.name}</TableBodyCell>
              {timeRanges.map(t => (
                <TableBodyCell key={t.key}>
                  {getGrowth({ goalId: goal.goalId, timeRange: t })}%
                </TableBodyCell>
              ))}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      <div>
        {i18n(
          'report.historicalReturn.historicalDevelopmentInPercent.footer',
          translationsConfig
        )}
      </div>
    </div>
  );
};

export default HistoricalReturnTable;
